<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            shadow-sm
            border-0
            w-100
            card-container
            px-0 px-md-3
          "
        >
          <!-- Create/Edit Compliance Slide form -->
          <form
            id="compliance-slide-form"
            class="
              d-flex
              flex-row flex-wrap
              align-items-start
              justify-content-start
              mx-0
              px-0
              py-0
              h-100
              body-container
            "
          >
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                class="
                  text-left text-black
                  headings
                  font-primary
                  mb-4
                  mt-3
                  py-3
                "
              >
                {{
                  isEdit 
                    ? type == "normal" ? "Edit compliance slide" : "Edit compliance image"
                    : type == "normal" ? "Add a new compliance slide" : "Add a new compliance image"
                }}
              </h6>
              <!-- full row input (Slide Title) -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="slide-title-group"
                    id="slide-title-group-input"
                    class="text-prime-gray"
                    :state="titleError.status"
                    :invalidFeedback="`${titleError.message}`"
                    label="Slide Title"
                    v-model="title"
                    isRequired
                    trim
                    :counter="titleMaxCount"
                    form="compliance-slide-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Slide Text Color) -->
              <b-row
                v-if="this.$route.meta.type != 'image'"
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  py-4
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormSelect
                    groupId="slide-text-color-group"
                    id="slide-text-color-group-select"
                    class="text-prime-gray"
                    :state="textColorError.status"
                    :invalidFeedback="`${textColorError.message}`"
                    label="Text Color"
                    v-model="selectedTextColor"
                    isRequired
                    trim
                    form="compliance-slide-form"
                    :options="textColorOptions"
                  ></FormSelect>
                  <p class="text-prime-gray text-left font-primary mt-1">
                    Make sure to choose a color that would contrast with the
                    background image
                  </p>
                </b-col>
              </b-row>
              <!-- image upload guidelines -->
              <h6 class="text-left text-dark headings font-primary mb-2 mt-3">
                Slide background guidelines
              </h6>
              <ul
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mt-3
                "
              >
                <li class="sub-points">
                  Images must be at least
                  <span class="font-weight-bold"> 1280x720 </span> size, in
                  order to prevent any black bars in the video slide
                </li>
                <li class="sub-points">
                  JPEG is the preferred format, but you may also use PNG
                </li>
                <li v-if="this.$route.meta.type != 'image'" class="sub-points">
                  Ensure that the images have a clean background in order for
                  the overlayed text to be legible
                </li>
                <li class="sub-points">
                  Images must be less than 5MB in size.
                </li>
              </ul>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                class="text-left text-dark headings font-primary mb-4 mt-3 py-3"
              >
                Slide background image
              </h6>
              <!-- full row image uploader -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  image-uploader-row
                "
                no-gutters
              >
                <!-- will be using the 16:9 image ratio for cropping -->
                <b-col cols="12">
                  <template v-if="imageFile || imageUrl">
                    <img class="d-block h-auto image-preview" :src="imageUrl" />
                    <b-row
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                      "
                      no-gutters
                    >
                      <!-- remove button -->
                      <b-button
                        class="remove-button w-full"
                        @click="removeLinkFn"
                        variant="outline-danger"
                        block
                        >Remove Image</b-button
                      >
                    </b-row>
                  </template>
                  <template v-else>
                    <ImageUploader
                      @getImage="handleImageChanged"
                      placeholder="Upload your associated media files here"
                    />
                  </template>
                  <div
                    v-if="imageFileError.status == false"
                    class="alert alert-danger alert-dismissible fade show mt-2"
                    role="alert"
                  >
                    <strong>Error: </strong> {{ imageFileError.message }}
                    <button
                      @click="imageFileError.status = null"
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="
                d-flex
                flex-column
                align-items-end
                justify-content-end
                align-self-end
                mb-4
              "
            >
              <!-- form action -->
              <b-row
                class="
                  d-flex
                  flex-column-reverse flex-md-row
                  align-items-center
                  justify-content-end
                  mt-3
                  w-100
                "
                no-gutters
              >
                <!-- cancel button -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    pr-0 pr-md-2
                    py-2
                  "
                  cols="12"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <FormButton
                    @click.native="resetForm"
                    variant="light"
                    isBlock
                    class="font-weight-bold text-secondary"
                    type="reset"
                    >Reset</FormButton
                  >
                </b-col>
                <!-- save and exit button -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    pl-0 pl-md-2
                    py-2
                  "
                  cols="12"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <FormButton
                    id="save-question-btn"
                    variant="primary"
                    isBlock
                    :disabled="isAPILoading"
                    :isLoading="isAPILoading"
                    class="font-weight-normal text-white"
                    type="submit"
                    @click.native="addNewSlideFn()"
                    v-b-popover.hover
                    :title="apiConfirmationText"
                    >Save and Exit</FormButton
                  >
                  <b-tooltip
                    id="helper-tooltip"
                    target="save-question-btn"
                    triggers="manual"
                    placement="top"
                    boundary="window"
                    offset="0"
                  >
                    {{ apiConfirmationText }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-col>
          </form>
        </b-card>
      </b-row>
      <b-overlay :show="initLoading" no-wrap></b-overlay>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";

// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormButton from "@/components/Form/FormButton";
import ImageUploader from "@/components/ImageUpload/ImageUploader";

// services
import {
  // GetSingleComplianceSlide,
  CreateComplianceSlide,
  UpdateComplianceSlide,
  GetSingleComplianceSlide,
} from "@/services/complianceSlides.service";

export default {
  name: "ComplianceSlideCreate",
  components: {
    HomeLayout,
    FormInput,
    ImageUploader,
    FormButton,
    FormSelect,
  },
  data() {
    return {
      title: null,
      titleMaxCount: 80,
      selectedTextColor: null,
      isFormReset: false,
      initLoading: false,
      textColorOptions: [
        {
          value: "white",
          text: "White",
        },
        {
          value: "black",
          text: "Black",
        },
        {
          value: "red",
          text: "Red",
        },
        {
          value: "blue",
          text: "Blue",
        },
        {
          value: "green",
          text: "Green",
        },
        {
          value: "yellow",
          text: "Yellow",
        },
      ],
      imageFile: null,
      imageUrl: null,
      imageBlob: null,
      isAPILoading: false,
      apiConfirmationText: null,
      isEdit: false,
      type: "image",
      titleError: {
        status: null,
        message: "",
      },
      textColorError: {
        status: null,
        message: "",
      },
      imageFileError: {
        status: null,
        message: "",
      },
    };
  },
  mounted() {
    if (this.$route.meta.type) this.type = this.$route.meta.type;
    if (this.$route.meta.isEdit) {
      this.isEdit = true;
      this.initEditComplianceSlideFn(this.$route.params.id);
    }
  },
  watch: {
    title(val) {
      // validate title
      if (!this.isFormReset) {
        let titleErrors = this.validateTitle(val);
        if (titleErrors.length > 0) {
          this.titleError.status = false;
          this.titleError.message = titleErrors[0];
        } else this.titleError.status = true;
      }
      if (val) {
        this.titleError.status = true;
        if (val.length <= 80) {
          this.titleError.status = true;
        } else {
          this.titleError.status = false;
          this.titleError.message =
            "Video Title should have less than 80 characters";
        }
      } else {
        this.titleError.status = false;
        this.titleError.message = "Please fill in the Video Title";
      }
    },
    selectedTextColor(val) {
      if (!this.isFormReset) {
        // validate text options
        let textColorErrors = this.validateTextColor(val);
        if (textColorErrors.length > 0) {
          this.textColorError.status = false;
          this.textColorError.message = textColorErrors[0];
        } else this.textColorError.status = true;
      }
    },
    imageFile(val) {
      if (!this.isFormReset) {
        let imageFileErrors = this.validateImageFile(val);
        if (imageFileErrors.length > 0) {
          this.imageFileError.status = false;
          this.imageFileError.message = imageFileErrors[0];
        } else this.imageFileError.status = true;
      }

      if ("File" in window && val instanceof File) {
        // check if it is an image file of type png or jpeg
        if ((val.type && val.type == "image/jpeg") || val.type == "image/png") {
          // check if the video's size is less than 5mb
          if (val.size && val.size < 5 * 1024 * 1024) {
            // clear any error and mark form file as valid
            this.imageFileError.status = true;
            this.imageFileError.message = "";
          } else {
            this.imageFileError.status = false;
            this.imageFileError.message =
              "Please upload an image file that is less than 5MB in size";
          }
        } else {
          this.imageFileError.status = false;
          this.imageFileError.message =
            "Please upload a valid image file in either .jpeg or .png formats";
        }
      }
    },
  },
  computed: {
    computedTitleValidation() {
      if (this.title) {
        if (this.title.length <= this.titleMaxCount) {
          return true;
        }
        return false;
      } else return false;
    },
  },
  methods: {
    // add new slide function
    async addNewSlideFn() {
      // if (this.computedTitleValidation || this.$route.meta.type == "image") {
        this.isAPILoading = true;
        var errResult = this.errorFn();
        if (errResult.length == 0 && this.computedTitleValidation) {
          var newFile = new File([this.imageBlob], "name.jpeg", {
            lastModified: 1534584790000,
            type: "image/jpeg",
          });
          var formData = new FormData();
          formData.append("slideTitle", this.title);
          formData.append("textColor", this.selectedTextColor);
          formData.append("slideBackgroundImage", newFile);
          formData.append("type", this.$route.meta.type);

          // set tootlip text
          if (this.isAPILoading) {
            this.apiConfirmationText =
              "Please wait! This might take a few seconds";
            this.$root.$emit("bv::show::tooltip", "helper-tooltip");
          }

          if (this.isEdit) {
            // update
            let id = this.$route.params.id;
            formData.append("id", id);
            UpdateComplianceSlide(id, formData)
              .then((res) => {
                if (res.data.code == 200 || res.data.message == "success") {
                  this.isAPILoading = false;
                  this.apiConfirmationText = null;
                  let payloadNotify = {
                    isToast: true,
                    title: "Slide was updated successfully",
                    content: "Slide has been updated successfully",
                    variant: "success",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  this.$router.push({ name: "Videos" });
                } else this.handleSubmitError(res);
              })
              .catch((err) => this.handleSubmitError(err.details));
          } else {
            CreateComplianceSlide(formData)
              .then((res) => {
                if (res.data.code == 200 || res.data.message == "success") {
                  this.isAPILoading = false;
                  this.apiConfirmationText = null;
                  let payloadNotify = {
                    isToast: true,
                    title: "Slide was added successfully",
                    content: "Slide has been added successfully",
                    variant: "success",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  this.$router.push({ name: "Videos" });
                } else this.handleSubmitError(res.details);
              })
              .catch((err) => this.handleSubmitError(err));
          }
        } else this.handleSubmitError(errResult);
      // }
    },
    // initialize edit slide function
    async initEditComplianceSlideFn(val) {
      let id = val;
      try {
        this.initLoading = true;
        let { data } = await GetSingleComplianceSlide(id);
        if (data.code == 200 || data.message == "success") {
          let result = data.content;
         this.title = result.slideTitle;
         //this.imageBlob = result.slideBackgroundImage;
         this.selectedTextColor = result.textColor;
         let { blob, file } = await this.getFileFromURL(
            result.slideBackgroundImage,
            "slideBackgroundImage.jpeg",
            "image/jpeg"
          );
          this.imageFile = file;
          this.imageBlob = blob;
          this.imageUrl = result.slideBackgroundImage;
      // imageBlob: null,

          this.initLoading = false;
        } else {
          this.initLoading = false;
        }
      } catch (error) {
        this.initLoading = false;
      }
    },
    resetForm() {
      this.isFormReset = true;
      this.title = null;
      this.selectedTextColor = null;
      this.imageFile = null;
      this.imageUrl = null;
      this.imageBlob = null;

      this.titleError.status = null;
      this.textColorError.status = null;
      this.imageFileError.status = null;

      setTimeout(() => {
        this.isFormReset = false;
      }, 100);
    },
    removeLinkFn() {
      this.imageFile = null;
      this.imageUrl = null;
    },
    handleImageChanged({ imageBlob, cropperImage }) {
      this.imageFile = cropperImage.chosenFile;
      this.imageUrl = cropperImage.generateDataUrl();
      this.imageBlob = imageBlob;
    },
    handleSubmitError(errDetails) {
      this.isAPILoading = false;
      this.apiConfirmationText = null;

      let payloadNotify = {
        isToast: true,
        title: "ERROR! Unable to save compliance slide",
        // content: errDetails ,
        content: !Array.isArray(errDetails) ? errDetails : null,
        list: Array.isArray(errDetails) ? errDetails : null,
        variant: "danger",
      };

      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn() {
      // error list to have an array of all the errors to be displayed as a list
      var errorList = [];

      // validate title
      let titleErrors = this.validateTitle();
      if (titleErrors.length > 0) {
        this.titleError.status = false;
        this.titleError.message = titleErrors[0];
        errorList = [...errorList, ...titleErrors];
      }

      // validate text options
      let textColorErrors = this.validateTextColor();
      if (textColorErrors.length > 0 && this.$route.meta.type != "image") {
        this.textColorError.status = false;
        this.textColorError.message = textColorErrors[0];
        errorList = [...errorList, ...textColorErrors];
      }

      // validate text options
      let imageFileErrors = this.validateImageFile();
      if (imageFileErrors.length > 0) {
        this.imageFileError.status = false;
        this.imageFileError.message = imageFileErrors[0];
        errorList = [...errorList, ...imageFileErrors];
      }

      return errorList;
    },
    validateTitle(param) {
      let errorList = [];
      let value = param ? param : this.title;

      // validate title
      if (!value) errorList.push("Please fill in the compliance slide title");
      else if (this.value > this.titleMaxCount)
        errorList.push(
          `Slider title should have less than ${this.titleMaxCount} characters`
        );

      return errorList;
    },
    validateTextColor(param) {
      let errorList = [];
      let value = param ? param : this.selectedTextColor;

      // validate text options
      if (!value)
        errorList.push("Please select the compliance slide text color");
      else if (
        !this.textColorOptions.some((el) => {
          return el.value === value;
        })
      )
        errorList.push("Selected compliance slide text color is not valid");

      return errorList;
    },
    validateImageFile(param) {
      let errorList = [];
      let value = param ? param : this.imageFile;

      // validate text options
      if (!value) {
        this.imageFileError.status = false;
        this.imageFileError.message =
          "Please upload the compliance slide background image";

        errorList.push("Please upload the compliance slide background image");
      } else {
        if ("File" in window && value instanceof File == false)
          errorList.push(
            "Please upload a valid file as the compliance slide background file"
          );

        if (
          value.type &&
          value.type != "image/jpeg" &&
          value.type != "image/png"
        )
          errorList.push(
            "Please upload a valid image file in either .jpeg or .png formats"
          );

        if (
          value.size &&
          value.size > 5 * 1024 * 1024 //5 mb
        )
          errorList.push(
            "Please upload an image file that is less than 5MB in size"
          );
      }
      return errorList;
    },
    async getFileFromURL(url, name, defaultType = "image/jpeg") {
      if (url && url.length > 0) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();

          const file = new File([blob], name, {
            type: response.headers.get("content-type") || defaultType,
          });

          return {
            blob,
            file,
          };
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
/* container card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px;
  border: 1px solid var(--light);
}

.card-row .sub-points {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 24px;
}

.card-row .headings {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  /* font-weight: bolder; */
}

/* custom checkbox */
.custom-checkbox,
.custom-checkbox-active {
  cursor: pointer;
}

/* checkbox color (default state) */
.custom-checkbox >>> .custom-control-label,
.custom-checkbox-active >>> .custom-control-label {
  user-select: none;
}

.custom-checkbox >>> .custom-control-label::before {
  background-color: var(--ghost-white);
  border-color: transparent;
}

.custom-checkbox >>> .custom-control-label::after {
  background-color: var(--white);
}

/* override default bootstrap styles */
.custom-checkbox
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

/* checkbox color (active state) */
.custom-checkbox-active >>> .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

/* override default bootstrap styles */
.custom-checkbox-active
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox-active
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.custom-checkbox-active
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.image-uploader-row {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.image-uploader-row .image-preview {
  width: 100%;
  min-width: 100%;
}
.image-uploader-row .remove-button {
  /* width: 400px; */
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.image-uploader-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.image-uploader-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.image-uploader-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .image-uploader-row {
    padding: 1rem 0;
  }
}
</style>
